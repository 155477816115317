
.web_login {
    width: 100%;
    /* padding: 80px 0; */
    position: relative;
    /* background-color: #2b76b9; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0,55,191);
    background: radial-gradient(circle, rgba(0,55,191,1) 0%, rgba(0,33,115,1) 100%);
}

.web_login h1 {
    font-size: 30px;
    color: #000;
    text-align: center;
}


.login_form {
    margin: 0 auto;
    width: 450px;
    /* background-color: rgb(255 255 255 / 18%); */
    /* border-radius: 10px; */
    /* -webkit-backdrop-filter: blur(1px); */
    /* backdrop-filter: blur(3px); */
    /* border: 1px solid rgb(0 0 0 / 10%); */
    /* box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2); */
    /* padding: 40px 25px; */
}

@media (max-width: 480px) {
    .login_form {
        width: 100%;
        padding: 10px 0;
    }
}