
/* ============================================ backend ========================================= */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
::selection {
    color: white;
    background: #002173;
  }
body{
    background-color: #f4f6fc!important; 
}
.custom-scrollbar{
    overflow: auto;
}
.custom-scrollbar::-webkit-scrollbar,.table-responsive::-webkit-scrollbar,body::-webkit-scrollbar {
    height: 6px;
    width:  6px;
}
/*Track */
.custom-scrollbar::-webkit-scrollbar-track,.table-responsive::-webkit-scrollbar-track,body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}
/*Handle */
.custom-scrollbar::-webkit-scrollbar-thumb,.table-responsive::-webkit-scrollbar-thumb,body::-webkit-scrollbar-thumb {
    background: #0037bf42;
    border-radius: 6px;
    box-shadow: inset 0 0 5px 3px #0037bf;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover, .table-responsive::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
    background: #0037bf8f;
    scrollbar-color: #0037bf8f #0037bf8f;
}

span,p,a {
    font-family: 'Rubik', sans-serif!important;
}

.search_btn_check{
    margin-bottom: 0px!important;
}
.search_btn_check > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.search_btn_check .selectBox span{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    line-height:15px;
}

.search_btn_check > .selectBox svg{
    height: 28px;
    width: auto;
}

.search_btn_check .shapeBox.selectBox{
    width: 42px;
    height: 42px;
}

.search_btn_check > .selectBox {
    cursor: pointer;
    /* padding: 7px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    text-align: center;
    border-radius: 5px;
    background-color: #0037bf10;
    border: 1px solid #0037bf15;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.search_btn_check > input:checked ~ .selectBox {
    background-color: #0037bf;
}
.selectBox:hover {
    border:1px solid #0037bf90;
    box-shadow: 0 8px 6px -6px #777;
    transition: all .3s ease-in-out;    
    transform: scale(1.07);
}
.search_btn_check .wColorBox.selectBox{
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .wClarityBox.selectBox{
    width: 40px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .wFluorBox.selectBox{
    width: 90px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .wLabBox.selectBox{
    width: 84px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .clarityBox.selectBox{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .labBox.selectBox{
    width: 85px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shortcutBox.selectBox{
    width: 230px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .finishBox.selectBox{
    width: 55px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .fancyBox.selectBox{
    width: 100px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .flourBox.selectBox{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .treatBox.selectBox{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .tingeBox.selectBox{
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shapeBox.selectBox span{
    font-size: 11px;
    padding-top: 5px;
    display: inline-block;
    width: 65px;
}
.tooltip-inner{
    text-transform: capitalize;
}

.search_btn_check > input:checked ~ .selectBox g [fill] {
    transition: fill 0.3s ease;
    stroke: #fff;
}
.search_btn_check > input:checked ~ .selectBox span {
    transition: fill 0.3s ease;
    color: #fff;
}

.search_accordion .card .card-header{
    padding: 12px 26px 9px 20px!important;
    background-color: #00c897!important;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}

.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}
.carat{
    display: none!important;
}
.carat.show{
    display: flex!important;
    flex-wrap: wrap;
}

.img_thumb{
    text-align: center;
    list-style-type: none;
    height: 50px;
    width: 50px;
    border: 1px solid #00000010;
    border-radius: 5px;
    margin-bottom:5px;
    overflow: hidden;
    background-color: #bcbebd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_thumb img{
    height: 48px;
    margin: 0 auto;
}
.img_box{
    margin-left:10px ;
    width: 370px;
    height: 328px;
    background-color: #EBEDF3;
    text-align: center;
}
.img_box img{
    height: 328px;
    margin: 0 auto;
}

.product_detail .img_box{
    margin-left:10px ;
    width: 470px;
    height: 400px;
    background-color: #EBEDF3;
    text-align: center;
}
.product_detail .img_box img{
    height: 400px;
    margin: 0 auto;
}
.search_result_dropdown .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after{
    content: ""!important;
}
.minHeight{
    min-height: 49px!important;
}

.gridData.card{
    border-radius: 0;
    box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 10%);
    margin: 10px 0px;
}

.gridData.card:hover {
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 24%);
    transition: all .3s ease-in-out;
    transform: scale(1.02);
    border-radius:5px;
}

.gridData.card .img-fluid{
    max-height: 250px;
    min-height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}
.table-hover tbody tr.expanded_tr:hover{
    background-color: #fff!important;
}
.table-hover tbody tr.expanded_tr:hover th{
    background-color: #f3f5f9;
}
.sh_expand{
    margin:15px;
}
.sh_expand:hover{
    background-color: #fff;
}

.sh_expand:hover th{
    background-color: #f3f5f9;
}

.table-hover td .sh_expand tbody tr:hover {
    background-color: rgba(54,153,255,.12)!important;
}

.table-hover tbody tr:hover {
    background-color: rgba(54,153,255,.12)!important;
}

.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}
.btn-seprator{
    z-index: 2;
    background-color: #fff;
    position: relative;
    display: inline-block;
    padding: 0px 10px;
}
.custom-seprator{
    border-top: 1px solid #3699FF;
    position: absolute;
    top: 16px;
    left: 25%;
    width: 50%;
    z-index: 1;
}
.search-result .MuiTab-root {
    min-width: auto!important;
    min-height: auto!important;
    padding: 10px;
}
.search-result .MuiTabs-root {
    min-height: auto!important;
}
.search-result .MuiTabs-indicator {
    display: none!important;
}
.search-result-list .MuiTypography-root{
    padding: 0px!important;
}
.select_block {
    position: relative;
    margin-left: 800px;
}
.select_block_border{
    align-items: center;
    display: flex;
}